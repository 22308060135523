import React from "react"
import Layout from "@components/layout"
import Seo from "@components/seo"
import SolutionsSidebar from "@components/solutionsSidebar"
import { graphql } from "gatsby"
const ManagedServices = ({ data }) => (
  <Layout>
    <Seo
      title="Managed Services"
      description={data.site.siteMetadata.description}
    />
    <div className="page-headline">
      <div className="container">
        <h6 className="text-white-50 text-uppercase">Solutions</h6>
        <h1>Managed Services</h1>
      </div>
    </div>
    <div className="page-content bg-light">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-7 col-lg-8">
            <p>
              Our strategy in Managed Services is based on Industry Standards
              and Best Practices designed to secure the network, reduce the
              amount of time required to operate and control the network
              resources, manage the assets, and reduce lost revenue and
              productivity associated with poor network performance and
              downtime.
            </p>
            <h4>Incident Management</h4>
            <p>
              We will become the primary point of contact and will troubleshoot
              with vendors, carriers & internal IT staff for quick problem
              resolution.
            </p>
            <h4>Asset Management</h4>
            <p>
              Maximizing efficiency and uptime is based on full knowledge of the
              components and configurations of the equipment and services in the
              network. We continually document, capture device configurations,
              perform updates and patches, track vendor contracts and test the
              network devices to ensure maximum performance.
            </p>
            <h4>Endpoint Security & Management</h4>
            <p>
              User devices comprise the functional components where the
              day-to-day work actually gets completed. We monitor and secure
              devices by ensuring that patches and updates are continually
              applied, malware, viruses, security breaches and risks are
              detected and stopped before the network is exposed to harmful
              incidents.
            </p>
            <h4>Network Monitoring and Notification</h4>
            <p>
              We proactively monitor critical network equipment and detect
              network outages and problems. Trouble tickets are automatically
              generated for all out-of-service incidents and escalated to our
              team of technicians for resolution.
            </p>
            <h4>Change Management</h4>
            <p>
              IT Specialists will establish client points of contact to become
              the representatives for authorizing changes to network resources.
            </p>
            <h4>Backup and Disaster Recovery</h4>
            <p>
              Business data is the single most valuable asset of any
              organization. To lose is puts the very survival of the business at
              risk. We develop and implement a predefined comprehensive plan
              that defines, executes, tests, and recovers data on a continuous
              and monitored basis.
            </p>
            <h4>Help Desk</h4>
            <p>
              We provide the end users with the technical support and service
              needed at the time its needed. Printing, application support,
              network issues, security, access, staff changes and many other
              aspects of the day to day operations are managed by our team. Our
              help desk staff are trained and ready to handle the specifics of
              your environment.
            </p>
          </div>
          <div className="col-md-4 col-lg-3">
            <SolutionsSidebar />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ManagedServices

export const ManagedServicesQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
